<template>
  <div>
    <vue-apex-charts type="pie" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components:{
    VueApexCharts
  },
  data() {
    return {
      series: [562.474, 374.984],
      chartOptions: {
        colors: ['#1490F7','#FF5306'],
        legend: {
          position: 'bottom'
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '15px',
              },
              value: {
                fontSize: '16px',
              }, 
              total: {
                show: true,
                label: 'Total Penduduk',
                formatter: function() {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 5000
                }
              }
            }
          }
        },
        labels: ['OAP', 'Non OAP'],
      }
    }
  },
  mounted() {
    this.getChartData()
  },
  methods: {
    getChartData() {
      this.$axios.get('/v1/sensus/pie-oap-vs-non')
        .then(response => {
          let result = response.data

          this.series = result.series
          this.chartOptions = {
            colors: ['#1490F7','#FF5306'],
            legend: {
              position: 'bottom'
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '15px',
                  },
                  value: {
                    fontSize: '16px',
                  }, 
                  total: {
                    show: true,
                    label: 'Total Penduduk',
                    formatter: function() {
                      // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                      return result.series.reduce((a,b) => a + b, 0) / result.series.length
                    }
                  }
                }
              }
            },
            labels: result.labels,
          }
        })
    }
  }
 }
 </script>