<template>
  <div>
    <highcharts ref="barChart" :options="chartOptions"></highcharts>
  </div>
</template>
<script>
export default {
  name: "chart-kepala-keluarga",
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: [
           "Total KK",
              "Total KK Laki Laki",
              "Total KK Perempuan",
              "Total KK > 65"
          ]
        },
        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal +
              " KK"
            );
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        legend: {
          enabled: true,
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
              enabled: true
            }
          }
        },
        series: [
          {
            name: "Non-OAP",
             data: [36, 35, 20, 50],
                color:'#808080'
          },
          {
            name: "OAP",
            data: [29, 25, 10, 20],
            color: "#07980C"
            
          }
        ]
      },
    };
  },
  mounted() {
    this.getDataChart()
  },
  methods: {
    getDataChart() {
      this.$axios.get('/v1/sensus/kepala-keluarga')
        .then(response => {
          let result = response.data
          this.chartOptions = {
            chart: {
              type: "bar"
            },
            title: {
              text: ""
            },
            xAxis: {
              categories: result.xAxis.categories
            },
            tooltip: {
              formatter: function() {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal +
                  " KK"
                );
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: ""
              }
            },
            legend: {
              enabled: true,
              reversed: true
            },
            plotOptions: {
              series: {
                stacking: "normal",
                dataLabels: {
                  enabled: true
                }
              }
            },
            series: result.series
          }
        })
    }
  }
};
</script>