<template>
  <div>
    <div class="cotainer-standar text-center">
      <div class="dk-container">
        <span
          class="d-block font-30 font-res-16 mt-res-30 text-white font-weight-6 mb-40  pl-res-5 pr-res-5 "
        >
          DATA KEPENDUDUKAN PROVINSI PAPUA BARAT
          <br />TAHUN {{ new Date().getFullYear() }}
        </span>
        <div class="container-standar mt-10 mb-10 pb-10">
          <div class="row">
            <div class="col-md-12 mb-10">
              <div class="card shadow">
                <div class="row">
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-data.svg"
                          alt
                        />
                      </div>
                      <h4
                        class="  font-20 text-center font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Jiwa
                        <br />
                        <strong>{{ total_jiwa | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-laki.svg"
                          alt
                        />
                      </div>
                      <h4
                        class=" text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Laki Laki
                        <br />
                        <strong>{{ total_pria | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-perempuan.svg"
                          alt
                        />
                      </div>
                      <h4
                        class="  text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Perempuan
                        <br />
                        <strong>{{ total_perempuan | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-jiwa.svg"
                          alt
                        />
                      </div>
                      <h4
                        class=" text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total KK
                        <br />
                        <strong>{{ total_kk | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 d-inline-flex">
              <div class="shadow card pad-12 w-100">
                <span
                  class="font-18 text-primary text-center pl-4 mb-10 font-weight-6"
                >
                  <strong>PENDUDUK SESUAI USIA </strong>
                </span>
                <!-- <span class="flex justify-center    w-100">
                  <selecYears class="width-50 w-res-80  "></selecYears>
                </span> -->
                <bar-detail></bar-detail>
              </div>
            </div>
            <div class="col-md-8 d-inline-flex">
              <div class="card w-full shadow">
                <span
                  class="font-18  font-res-16 pb-res-10 text-primary text-center mt-10 pl-4 mb-10 font-weight-6"
                >
                  <strong>
                    PENDUDUK PAPUA BARAT
                    <br />
                    OAP/Non-OAP
                  </strong>
                </span>
                <!-- <span class="flex justify-center    w-100">
                  <selecYears class="width-30  w-res-80   "></selecYears>
                </span> -->
                <!-- <donutchart></donutchart> -->
                <grapichpenduduk class="w-full"></grapichpenduduk>
              </div>
            </div>
          </div>
        </div>
        <div class="container-standar mt-0 mb-10">
          <div class="row">
            <div class="col-lg-12   mt-6">
              <div class="card width-100 pt-10 pb-10 shadow">
                <span
                  class="font-18 font-res-16 text-primary text-center pl-4 mb-10 font-weight-6"
                >
                  <strong>MAP PERSEBARAN </strong>
                </span>
                <!-- <span class="flex justify-center    w-100">
                  <selecYears class="width-30 w-res-80  "></selecYears>
                </span> -->
                <!-- <div class="img-res ">
                  <div class="overflow-stacked">
                  <maps-component></maps-component>
                  <div class="zoom" @click="zoom()">
                    zoom
                  </div> 
                  </div>    
                   <a class="d-block width-80 text-right margin-auto pt-2 font-weight-6" href="https://papua.bps.go.id/publication/2019/08/16/e43fc034219586add3878e48/provinsi-papua-dalam-angka-2019.html">Source: papua.bps.go.id</a>
                </div> -->

                <div class="container ">
                  <div class="width-70  w-res-100 float-left ">
                    <img
                      class="contain-img"
                      src="@/assets/images/map/maps_all.png"
                      alt=""
                    />
                  </div>
                  <div class="width-30  mt-10  w-res-100 float-left ">
                    <div
                      class="accordion width-100"
                      role="tablist"
                      v-for="(items, index) in datapendudukMap"
                      :key="index"
                    >
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle="'accordion-' + items.id"
                          :class="`accord-` + items.id"
                          >{{ items.nama }}
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        :id="`accordion-` + items.id"
                        visible
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <div class="row">
                            <div class="col-md-6 mb-5">
                              <h2 class=" text-left">Data Perempuan:</h2>
                            </div>
                            <div class="col-md-6 mb-5">
                              <h2 class=" text-right">
                                <strong>{{ items.perempuan }} Orang </strong>
                              </h2>
                            </div>
                            <div class="col-md-6">
                              <h2 class=" text-left">Data Laki-Laki:</h2>
                            </div>
                            <div class="col-md-6 mb-5">
                              <h2 class=" text-right">
                                <strong>{{ items.laki_laki }} Orang </strong>
                              </h2>
                            </div>

                            <div class="col-md-6 mb-5">
                              <h2 class=" text-left">Data Keluarga :</h2>
                            </div>
                            <div class="col-md-6 mb-5">
                              <h2 class=" text-right">
                                <strong
                                  >{{ items.total_keluarga }} Orang
                                </strong>
                              </h2>
                            </div>

                            <div class="col-md-6 mb-5">
                              <h2 class=" text-left">
                                <strong> Total Jiwa: </strong>
                              </h2>
                            </div>
                            <div class="col-md-6 mb-5">
                              <h2 class=" text-right">
                                <strong>{{ items.total_jiwa }} Orang </strong>
                              </h2>
                            </div>
                          </div>
                        </b-card-body>
                      </b-collapse>

                      <!-- <b-card-header header-tag="header" class="p-1 " role="tab">
                            <b-button block v-b-toggle.accordion-2 class="accord-2" >KABUPATEN MANOKWARI</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <h2>2</h2>
                            </b-card-body>
                          </b-collapse> 
                          <b-card-header header-tag="header" class="p-1   " role="tab">
                            <b-button block v-b-toggle.accordion-3 class="accord-3">KABUPATEN FAK FAK</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                          <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-4 class="accord-4">KABUPATEN SORONG SELATAN</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                           <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-5 class="accord-5">KABUPATEN RAJA AMPAT</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                            <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-6 class="accord-6">KABUPATEN TELUK BINTUNI</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                          <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-7 class="accord-7">KABUPATEN TELUK WONDAMA</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                          <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-8 class="accord-8">KABUPATEN KAIMANA</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                           <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-9 class="accord-9">KABUPATEN MAYBRAT</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                           <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-10 class="accord-10">KOTA SORONG</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                             <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-11 class="accord-11">KABUPATEN TAMBRAUW</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                            <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-12 class="accord-12">KABUPATEN MANOKWARI SELATAN</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse>

                            <b-card-header header-tag="header" class="p-1  " role="tab">
                            <b-button block v-b-toggle.accordion-13 class="accord-13">KABUPATEN PEGUNUNGAN ARFAK</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-13" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                             <h2>2</h2>
                            </b-card-body>
                          </b-collapse> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 inline-flex mt-6 ">
              <div class="card width-100 pt-10 pb-10 shadow d-res-grid">
                <span
                  class="font-18 font-res-16 text-primary text-center pl-4 mb-10 font-weight-6"
                >
                  <strong>TABEL PERSEBARAN OAP PROVINSI</strong>
                </span>
                <!-- <span class="flex justify-center mb-10   w-100">
                  <selecYears class="width-30   w-res-80"></selecYears>
                </span> -->
                <div class="res-scroll">
                  <table class="table table-lg   table-xs  ">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Kabupaten</th>
                        <th scope="col">Laki Laki</th>
                        <th scope="col">Perempuan</th>
                        <th scope="col">Sub Total</th>
                        <th scope="col">Presentase dari Total OAP Provinsi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="index" v-for="(data, index, fname) in dataOAP">
                        <th>{{ fname + 1 }}</th>
                        <td>{{ data.nama_kabupaten }}</td>
                        <td>{{ data.male_count }}</td>
                        <td>{{ data.female_count }}</td>
                        <td>{{ data.subtotal }}</td>
                        <td>{{ data.percentage }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <span class="pl-4 mb-10 font-weight-6">
                   <a class="d-block width-80 text-right margin-auto pt-2 font-weight-6" href="https://papua.bps.go.id/publication/2019/08/16/e43fc034219586add3878e48/provinsi-papua-dalam-angka-2019.html">Source: papua.bps.go.id</a>
                </span> -->
              </div>
            </div>
            <div class="col-lg-12   inline-flex mt-6">
              <div class="card width-100 shadow pad-20">
                <b-card no-body>
                  <b-tabs
                    pills
                    card
                    vertical
                    nav-wrapper-class="w-25 w-res-100"
                  >
                    <b-tab
                      title="Data OAP dan Non OAP"
                      @click="chekedfunction(1)"
                    >
                      <b-card-text class="col-lg-12">
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data OAP dan Non OAP
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <pie-chart v-if="tabactive_1"></pie-chart>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Kepemilikan Adminduk"
                      @click="chekedfunction(2)"
                    >
                      <b-card-text class="col-lg-12">
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data Kepemilikan Adminduk Tahun
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <adminduk v-if="tabactive_2"></adminduk>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <!-- <b-tab
                      title="Pertumbuhan Penduduk"
                      @click="chekedfunction(3)"
                    >
                      <b-card-text class="col-lg-12">
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24 w-100 d-inline-block text-primary"
                            >
                              <strong
                                >PERTUMBUHAN PENDUDUK TAHUN
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <growth-chart v-if="tabactive_3"></growth-chart>
                            <span
                              class="d-inline-block w-full  text-primary text-center font-weight-6  "
                              >Source: papua.bps.go.id</span
                            >
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab> -->
                    <b-tab
                      title="Data Kepala Keluarga"
                      @click="chekedfunction(4)"
                    >
                      <b-card-text>
                        <div class="width-95 mt-10">
                          <span
                            class="font-24 text-primary text-center width-100 pl-4 mb-10 d-inline-block"
                          >
                            <strong
                              >DATA KEPALA KELUARGA TAHUN
                              {{ new Date().getFullYear() }}</strong
                            >
                          </span>
                          <grapichkk v-if="tabactive_4"></grapichkk>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Partisipasi Sekolah"
                      @click="chekedfunction(5)"
                    >
                      <b-card-text>
                        <div class="row text-center" v-if="tabactive_5">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data Partisipasi Sekolah Tahun
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <!-- <partisipasi></partisipasi> -->
                            <div class="res-scroll">
                              <table class="table table-striped w-80">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">
                                      Kelompok Berdasarkan Umur
                                    </th>
                                    <th scope="col">Belum Pernah Sekolah</th>
                                    <th scope="col">Masih Sekolah</th>
                                    <th scope="col">Tidak sekolah lagi</th>
                                    <th scope="col">Penyandang disabilitas</th>
                                    <th scope="col">
                                      Kepemilikan akte kelahiran
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template
                                    v-for="(value,
                                    name,
                                    index) in partisipasi_sekolah"
                                  >
                                    <tr v-bind:key="index">
                                      <th scope="row">{{ ++index }}</th>
                                      <td>
                                        <strong>{{ name }}</strong>
                                      </td>
                                      <td>{{ value.belum_sekolah }}</td>
                                      <td>{{ value.masih_sekolah }}</td>
                                      <td>{{ value.tidak_sekolah_lagi }}</td>
                                      <td>{{ value.disabilitas }}</td>
                                      <td>{{ value.punya_akta_lahir }}</td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Program Bantuan Sosial"
                      @click="chekedfunction(6)"
                    >
                      <b-card-text>
                        <div class="width-95 mt-10">
                          <span
                            class="font-24 text-primary text-center pl-4 mb-10 d-inline-block w-full"
                          >
                            <strong
                              >Program Bantuan Sosial Tahun
                              {{ new Date().getFullYear() }}</strong
                            >
                          </span>
                          <bansos v-if="this.tabactive_6"></bansos>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Kepemilikan Rumah" @click="chekedfunction(7)">
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data Kepemilikan Rumah Tahun
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <kepemilikanrumah
                              v-if="this.tabactive_7"
                            ></kepemilikanrumah>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Status Tempat Tinggal"
                      @click="chekedfunction(8)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong>
                                Data Kepemilikan Tempat Tinggal vs Kepemilikan
                                Tanah
                                <br />Tempat Tinggal Tahun
                                {{ new Date().getFullYear() }}
                              </strong>
                            </h4>
                            <statustempat
                              v-if="this.tabactive_8"
                            ></statustempat>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Tingkat Pendidikan Terakhir"
                      @click="chekedfunction(9)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >TINGKAT PENDIDIKAN TERAKHIR TAHUN
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <TingkatPendidikan
                              v-if="this.tabactive_9"
                            ></TingkatPendidikan>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Jenis Lantai Terluas"
                      @click="chekedfunction(10)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data Jenis Lantai Terluas Tahun
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <JenisLantai v-if="this.tabactive_10"></JenisLantai>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Jenis Dinding Terluas"
                      @click="chekedfunction(11)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data Jenis Dinding Terluas Tahun
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <JenisDinding
                              v-if="this.tabactive_11"
                            ></JenisDinding>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Sumber Air Minum" @click="chekedfunction(12)">
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data Sumber dan Cara Mendapatkan Air Bersih
                                Tahun {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <AirMinum v-if="this.tabactive_12"></AirMinum>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Sumber Penerangan"
                      @click="chekedfunction(13)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data Sumber Penerangan Tahun
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <SumberPenerangan
                              v-if="tabactive_13"
                            ></SumberPenerangan>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Bahan Bakar" @click="chekedfunction(14)">
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong
                                >Data Bahan Bakar Utama untuk Memasak Tahun
                                {{ new Date().getFullYear() }}</strong
                              >
                            </h4>
                            <BahanBakar v-if="tabactive_14"></BahanBakar>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
            </div>
          </div>
        </div>

        <div class="container-standar   mt-4 card pt-5 pl-3 pl-3 pb-0">
          <span
            class="d-block font-18 font-res-16 pl-res-5 pr-res-5 pt-res-5  text-primary font-weight-9 mb-10"
            >DATA KEPENDUDUKAN PROVINSI PAPUA BARAT</span
          >
          <!-- <span class="flex justify-center mb-10   w-100">
            <selecYears class="width-30 w-res-80   "></selecYears>
          </span> -->
          <div class="col-md-12 text-right mb-20">
            <b-button @click="exportExcel" variant="success"
              ><b-icon icon="download" aria-hidden="true"></b-icon> Download
              Excel</b-button
            >
          </div>
          <div class="res-scroll">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nama Kota/Kabupaten</th>
                  <th scope="col">Jumlah Distrik</th>
                  <th scope="col">Total Jiwa</th>
                  <th scope="col">Total KK</th>
                  <th scope="col">Laki Laki</th>
                  <th scope="col">Perempuan</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(data, index) in data_provinsi">
                  <tr v-bind:key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>
                      <a
                        class="text-primary font-weight-6 cursor-pointer"
                        @click="routerKabuaten(data.id)"
                        >{{ data.nama }}</a
                      >
                    </td>
                    <td>
                      <a
                        class="text-primary font-weight-6  cursor-pointer"
                        @click="showModalDetail(data.id)"
                        >{{ data.jumlah_distrik }} Distrik</a
                      >
                    </td>
                    <td>{{ data.total_jiwa }} Jiwa</td>
                    <td>{{ data.total_keluarga }}</td>
                    <td>{{ data.laki_laki }}</td>
                    <td>{{ data.perempuan }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="modal_detail" size="xl" title="Data Distrik ">
        <div class="res-scroll">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nama Distrik</th>
                <th scope="col">Jumlah Desa</th>
                <th scope="col">Total Jiwa</th>
                <th scope="col">Total KK</th>
                <th scope="col">Laki Laki</th>
                <th scope="col">Perempuan</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(district, index) in data_district" v-bind:key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td class="cursor-pointer">
                  <span
                    class="d-inline-block w-100 font-weight-6 text-primary"
                    @click="routerDesa(district.id, district.nama)"
                    >{{ district.nama }}
                  </span>
                </td>
                <td>
                  <span class="  font-weight-6"
                    >{{ district.jumlah_kampung }} Desa</span
                  >
                </td>
                <td>{{ district.total_jiwa }} Jiwa</td>
                <td>{{ district.total_keluarga }}</td>
                <td>{{ district.laki_laki }}</td>
                <td>{{ district.perempuan }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <span class="d-inline-block w-full float-right ">Source: papua.bps.go.id</span> -->
      </b-modal>
    </div>
  </div>
</template>
<script>
// import barchart from "./components/barchart";
// import donutchart from "./components/donutchart";
import PieChart from "./components/pieChart";
import grapichkk from "./components/grafik-kk";
import grapichpenduduk from "./components/grapichpenduduk";
// import GrowthChart from "./components/growthChart";
import BarDetail from "./components/BarDetail";
import TingkatPendidikan from "./components/TingkatPendidikan";
import adminduk from "./components/adminduk";
// import MapsComponent from "./components/mapsSVG";
import bansos from "./components/bansos";
// import MapBox from "./components/mapbox";
import kepemilikanrumah from "./components/kepemilikanrumah";
import statustempat from "./components/statustempattinggal";
import JenisLantai from "./components/jenislantai";
import JenisDinding from "./components/jenisdinding";
import AirMinum from "./components/airminum";
import SumberPenerangan from "./components/sumberpenerangan";
import BahanBakar from "./components/bahanbakar";
// import selecYears from "./components/select-years";
// import partisipasi from "./components/partisipasisekolah";
import XLSX from "xlsx";

export default {
  components: {
    // donutchart,
    grapichpenduduk,
    // selecYears,
    // GrowthChart,
    BarDetail,
    TingkatPendidikan,
    PieChart,
    // MapsComponent,
    grapichkk,
    adminduk,
    bansos,
    kepemilikanrumah,
    statustempat,
    // MapBox,
    JenisLantai,
    JenisDinding,
    AirMinum,
    SumberPenerangan,
    BahanBakar,
  },
  name: "data-kependudukan",
  data() {
    return {
      selected1: null,
      options1: [
        { value: null, text: "Tahun 2021" },
        { value: "a", text: "Tahun 2022" },
      ],
      datapendudukMap: [],
      dataOAP: [],
      total_pria: 0,
      total_perempuan: 0,
      total_kk: 0,
      total_jiwa: 0,
      partisipasi_sekolah: {},
      data_provinsi: [],
      data_district: [],
      tabactive_1: true,
      tabactive_2: false,
      tabactive_3: false,
      tabactive_4: false,
      tabactive_5: false,
      tabactive_6: false,
      tabactive_7: false,
      tabactive_8: false,
      tabactive_9: false,
      tabactive_10: false,
      tabactive_11: false,
      tabactive_12: false,
      tabactive_13: false,
      tabactive_14: false,
      export: {
        data_provinsi: [],
      },
    };
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    this.getTotalJenisPenduduk();
    this.getDataPartisipasiSekolah();
    this.persebaranOAP();
    this.getDataProvinsi();
  },
  methods: {
    chekedfunction(newVal) {
      this.tabactive_1 = false;
      this.tabactive_2 = false;
      this.tabactive_3 = false;
      this.tabactive_4 = false;
      this.tabactive_5 = false;
      this.tabactive_6 = false;
      this.tabactive_7 = false;
      this.tabactive_8 = false;
      this.tabactive_9 = false;
      this.tabactive_10 = false;
      this.tabactive_11 = false;
      this.tabactive_12 = false;
      this.tabactive_13 = false;
      this.tabactive_14 = false;
      if (newVal == 1) {
        this.tabactive_1 = true;
      }
      if (newVal == 2) {
        this.tabactive_2 = true;
      }
      if (newVal == 3) {
        this.tabactive_3 = true;
      }
      if (newVal == 4) {
        this.tabactive_4 = true;
      }
      if (newVal == 5) {
        this.tabactive_5 = true;
      }
      if (newVal == 6) {
        this.tabactive_6 = true;
      }
      if (newVal == 7) {
        this.tabactive_7 = true;
      }
      if (newVal == 8) {
        this.tabactive_8 = true;
      }
      if (newVal == 9) {
        this.tabactive_9 = true;
      }
      if (newVal == 10) {
        this.tabactive_10 = true;
      }
      if (newVal == 11) {
        this.tabactive_11 = true;
      }
      if (newVal == 12) {
        this.tabactive_12 = true;
      }
      if (newVal == 13) {
        this.tabactive_13 = true;
      }
      if (newVal == 14) {
        this.tabactive_14 = true;
      }
    },
    getTotalJenisPenduduk() {
      this.$axios.get("/v1/sensus/jiwa-kk-pria-wanita").then((response) => {
        let result = response.data;
        this.total_pria = result.pria.semua;
        this.total_perempuan = result.wanita.semua;
        this.total_jiwa = result.jiwa.semua;
        this.total_kk = result.kartu_keluarga.semua;
      });
    },
    getDataPartisipasiSekolah() {
      this.$axios.get("/v1/sensus/partisipasi-sekolah").then((response) => {
        let result = response.data;
        this.partisipasi_sekolah = result;
      });
    },
    persebaranOAP() {
      this.$axios.get("/v1/sensus/persebaran-oap").then((response) => {
        this.dataOAP = response.data;
      });
    },
    getDataProvinsi() {
      this.$axios.get("/v1/sensus/kependudukan-provinsi").then((response) => {
        let result = response.data;
        this.data_provinsi = result;
        this.datapendudukMap = result;
        let i = 0;
        result.forEach((e) => {
          this.export.data_provinsi.push({
            No: e.id,
            "Nama Kota/Kabupaten": e.nama,
            "Jumlah Distrik": e.jumlah_distrik,
            "Total Jiwa": e.total_jiwa,
            "Total KK": e.total_keluarga,
            "Laki-laki": e.laki_laki,
            Perempuan: e.perempuan,
          });
          i = i + 1;
        });
      });
    },
    showModalDetail(district_id) {
      this.$axios
        .get("/v1/sensus/city/kependudukan/" + district_id)
        .then((response) => {
          let result = response.data;
          this.data_district = result;
          this.$bvModal.show("modal_detail");
        });
    },
    routerKabuaten(id) {
      this.$router.push("/data-kabupaten/" + id);
      // setTimeout(() => {
      //   window.location.reload()
      // }, 500);
    },
    routerDesa(id, name) {
      this.$router.push("/data-desa/" + id);
      //   setTimeout(() => {
      //   window.location.reload()
      // }, 500);
      this.$store.commit("DISTRICT_STATE", name);
    },
    exportExcel() {
      // On Click Excel download button

      // export json to Worksheet of Excel
      // only array possible

      // A workbook is the name given to an Excel file
      // make Workbook of Excel
      let objectMaxLength = [];
      for (let i = 0; i < this.export.data_provinsi.length; i++) {
        let value = Object.values(this.export.data_provinsi[i]);
        for (let j = 0; j < value.length; j++) {
          if (value[j] !== undefined) {
            if (typeof value[j] == "number") {
              objectMaxLength[j] = 15;
            } else {
              objectMaxLength[j] =
                objectMaxLength[j] >= value[j].length
                  ? (objectMaxLength[j] += 2)
                  : value[j].length;
            }
          } else {
            objectMaxLength[j] = 20;
          }
        }
      }

      var keluargaWscols = [
        { width: objectMaxLength[0] },
        { width: 30 },
        { width: objectMaxLength[2] },
        { width: objectMaxLength[3] },
        { width: objectMaxLength[4] },
        { width: objectMaxLength[5] },
        { width: objectMaxLength[6] },
      ];

      var keluargaWS = XLSX.utils.json_to_sheet(this.export.data_provinsi);
      var wb = XLSX.utils.book_new();

      // export Excel file
      setTimeout(() => {
        keluargaWS["!cols"] = keluargaWscols;

        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, keluargaWS, "Data Kota dan Kabupaten"); // sheetAName is name of Worksheet
        XLSX.writeFile(
          wb,
          "Data Kependudukan Provinsi Papua Barat - " + new Date() + ".xlsx"
        ); // name of the file is 'book.xlsx'
      }, 1000);
    },
  },
};
</script>
